import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import SectionImage from '../components/SectionImage'
import BannerInfo from '../components/BannerInfo'
import { useLangContext } from '../context/lang.context'
import { gContainer } from '../style/config/globals'
import FormService from '../page/Services/FormService/FormService'
import Layout from '../components/Layout'
import TransportAll from '../page/Transport/TransportAll/TransportAll'
import ButtonOutline from '../components/ButtonOutline'

export default function Transport({ data, location }) {
  const { lang, setLang } = useLangContext()
  const transport = data.transports.edges.filter(
    ({ node }) => node.lang.slug === lang
  )[0]?.node

  const urlArray = location?.pathname?.split('/').filter((e) => e)
  const urlLang = urlArray.length > 2 && urlArray[urlArray?.length - 1]
  const langs = ['en', 'pl']

  /* eslint-disable */
  useEffect(() => {
    if (langs.includes(urlLang)) setLang(urlLang)
  }, [])

  useEffect(() => {
    if (!urlLang && lang !== transportLang) setLang(transportLang)
  }, [lang])

  const TransportQuoteButton = {
    pl: 'Wyceń transport',
    en: 'Quote a transport',
  }

  const QuickContactButton = {
    pl: 'Skontaktuj się z nami',
    en: 'Contact us',
  }

  /* eslint-enable */
  if (!transport) return <div />

  const {
    title,
    desc,
    image,
    lang: { slug: transportLang },
    sections,
    slug,
  } = transport

  return (
    <Layout>
      <Seo
        title={title}
        description={desc}
        lang={transportLang}
        image={image.file.url}
      />
      <SectionImage
        title={title}
        desc={desc}
        back={'TRANSPORT'}
        image={getImage(image)}
        imagePosition='center center'
      />
      <div css={[gContainer]}>
        {sections?.map(({ title, subtitle, desc, image, points }, id) => (
          <BannerInfo
            key={id}
            title={title}
            subtitle={subtitle}
            desc={desc.desc}
            transport
            button={{ text: TransportQuoteButton[lang], to: `/transport-quote`, align: 'center' }}
            // points={points?.map((point) =>
            //   typeof point === 'object'
            //     ? point.text && point.link
            //       ? point
            //       : point.content
            //     : point
            // )}
            // image={getImage(image)}
          />
        ))}
        {/* <div>
          <ButtonOutline
            extraCss={{ marginBottom: '1rem' }}
            green>
            {TransportQuoteButton[lang]}
          </ButtonOutline>
          <ButtonOutline
            extraCss={{ marginBottom: '1rem' }}
            white>
            {QuickContactButton[lang]}
          </ButtonOutline>
        </div> */}
      </div>
      <TransportAll page={slug.slug} />
      <FormService pages={'transport'} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    transports: allContentfulTransport(
      filter: { slug: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          lang {
            slug
          }
          slug {
            slug
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
            # title
            file {
              url
            }
          }
          sections {
            desc {
              desc
            }
            title
            subtitle
            #points {
            # content
            # link
            # text
            #}
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
              title
            }
          }
          title
          # headlines
          desc
        }
      }
    }
  }
`
